.grid-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;

  grid-template-areas:
    "header"
    "main";
  height: 100vh;
}

.gridHeader {
  grid-area: header;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  z-index: 1;
}

.aside::-webkit-scrollbar {
  height: 0px;
  width: 1px;
  background: transparent;
}

.aside {
  grid-area: aside;
  background-color: #fcfcfc;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.main {
  grid-area: main;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  background-color: #f8fbff;
}
.main::-webkit-scrollbar {
  width: 1px;
}

/* flexing aside */
.aside {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 240px;
  position: fixed;
  z-index: 2;
  transform: translateX(-245px);
  transition: all 300ms ease;
}

.aside.active {
  transform: translateX(0);
}

/* responsive layout */
@media only screen and (min-width: 1024px) {
  .grid-container {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-areas:
      "aside header"
      "aside main";
  }

  .grid-container.fullscreen {
    grid-template-columns: 1fr;
    grid-template-areas:
      "header"
      "main";
  }

  .aside {
    display: flex;
    flex-direction: column;
    position: relative;
    transform: translateX(0);
  }

  .aside.reduceWidth {
    width: 10ch;
  }
}

.mainContentContainer {
  padding: 2rem 0rem;
}
